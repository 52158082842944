import React from "react";
import { ButtonContent } from "../global/boton/ButtonContent";
import Form from "../Contact/Form";

function HeroSection({ image1, image2, image3, image4, slogan, text }) {

  const bgImages = [
    image1,
    image2,
    image3,
    image4,
  ]
  return (
    <>
      <div className="pic-wrapper">
        {bgImages.map((item, index) => {
          return (
            <figure
              key={index}
              className={`pic-${index + 1}`}
              style={{ background: `url("${item}")` }}
            ></figure>
          );
        })}

        <div className="z-[9] relative w-4/5 mx-auto ">
          <div className="h-full pb-[5%] md:pb-[10%] pt-20 flex flex-wrap-reverse justify-end items-center gap-5">
            <div className="md:w-[50%] ">
              <h1 className="text-white">{slogan}</h1>
              <p className="text-white">{text}</p>
              <div className="flex justify-center md:block">
                <ButtonContent />
              </div>
            </div>
            <div className="bg-white rounded p-2">
              <Form />
            </div>
          </div>
          {/* <div className="w-1/2"></div> */}
        </div>
      </div>
    </>
  );
}

export default HeroSection;
